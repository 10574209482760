/* eslint-disable no-param-reassign */
//* Vue Client Filters : Use them as globalProperties when instantiating a Vue App on the client

const forceHttps = (url) => {
  const protocol = url.split('//')[0];
  let forcedUrl;
  if (protocol && protocol === 'http:') {
    forcedUrl = url.replace('http:', 'https:');
  } else if (protocol === '') {
    forcedUrl = `https:${url}`;
  } else if (protocol === 'https:') {
    forcedUrl = url;
  } else {
    forcedUrl = `https://${url}`;
  }
  return forcedUrl;
};
const formatDate = (date, lang = 'en', pubTimeZone = 'America/Chicago') => {
  if (!date) return '';
  let newDate = date;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date)) {
    newDate = new Date(date);
  } else {
    newDate = new Date(date * 1000);
  }
  // EN-format: MMMM DD, YYYY, 8:10 [AM/PM]
  // ES-format: DD [de] MMMM [de] YYYY h:mm [a. m.|p. m.]
  const options = {
    timeZone: pubTimeZone,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  let finalDate = newDate.toLocaleString(lang, options);
  finalDate = finalDate.replace('p. m.', 'pm');
  finalDate = finalDate.replace('a. m.', 'am');
  return finalDate;
};
const dateWasModified = (publishedDate, modifiedDate) => {
  const timeOffset = 300;
  if (modifiedDate && modifiedDate !== '') {
    return (
      parseInt(publishedDate, 10) + timeOffset <
      parseInt(modifiedDate, 10)
    );
  }
  return false;
};
const localizeDate = (date, locale) => {
  const { lang, pubTimeZone } = locale;
  return formatDate(date, lang, pubTimeZone);
};

const checkUpdateTime = (pubDate, modDate) => {
  return (parseInt(pubDate, 10) + 300) < parseInt(modDate, 10);
};
const turnIntoFree160 = (thumbnail) => {
  const imageUrl = thumbnail || '';
  return imageUrl.split('/').map(val => ((val.startsWith('FREE') || val.startsWith('LANDSCAPE')) ? 'FREE_160' : val)).join('/');
};

const urlSection = url => url.split('/').slice(0, 4).join('/');

const newsletterDefaultValues = (value, type, lang) => {
  const defaultValues = {
    email: lang === 'es' ? 'Su correo electr\u00F3nico' : 'Enter Email Address',
    submit: lang === 'es' ? 'SUSCRÍBASE' : 'SIGN UP',
    success: lang === 'es' ? 'Gracias por suscribirse; dentro de poco empezar\u00E1 a recibir su bolet\u00EDn de noticias.' : 'Thanks for subscribing; you should start to receive your newsletter shortly.',
    capText: lang === 'es' ? 'Este sitio est\u00E1 protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy?hl=es">Pol\u00EDtica de Privacidad</a> y <a href="https://policies.google.com/terms?hl=es">T\u00E9rminos de Servicio</a> de Google.' : 'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.',
  };
  return value || defaultValues[type];
};

const filters = {
  forceHttps,
  formatDate,
  dateWasModified,
  localizeDate,
  checkUpdateTime,
  turnIntoFree160,
  urlSection,
  newsletterDefaultValues,
};

// * Globally register filters into a Vue instance
const registerFiltersTo = (app) => {
  app.config.globalProperties.$vueFilters = filters;
};

module.exports = { registerFiltersTo };

