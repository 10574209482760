<template>
  <span id="main-content" class="refmark-hidden"></span>
  <section id="primary-content" class="video-detail-section" tabindex="0">
    <div class="primary-channel">
      <div>
        <h3>{{ videoStore.getChannel }}</h3>
      </div>
    </div>
    <video-card></video-card>
  </section>

  <related-stories></related-stories>
  <div id="zone-el-2" class="zone-el">
    <div class="h-sm"></div>
    <div class="d-sm"></div>
  </div>

  <trending-videos></trending-videos>
  <div id="zone-el-99" class="zone-el">
    <div class="h-sm"></div>
    <div class="d-sm"></div>
  </div>
</template>

<script>
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  import Requester from '../../../../app/api/Requester';
  import VideoCardComponent from './video-card-component.vue';
  import RelatedStoriesComponent from './related-stories-component.vue';
  import TrendingVideosComponent from './trending-videos-component.vue';
  
  export default {
    name: 'Video Detail',
    components: {
      videoCard: VideoCardComponent,
      relatedStories: RelatedStoriesComponent,
      trendingVideos: TrendingVideosComponent,
    },
    computed: {
      ...mapStores(useVideoStore),
    },
  }

</script>